import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/core/core.module';
import { RouterModule } from '@angular/router';

// Other Components
import { UserMenuComponent } from './user-menu/user-menu.component';
import { ArraySortPipe } from './array-sort-pipe';
import { StatisticsService } from './services/statistic.service';
import { ColorService } from './color.service';
import { UserImgLaodComponent } from './user-img/user-img-laod/user-img-laod.component';
import { UserImgModalComponent } from './user-img/user-img-modal/user-img-modal.component';

import { ImageCropperModule } from 'ngx-image-cropper';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
// import { UserImgServices} from './services/user-img.services';
import { RatingStarComponent } from './rating-star/rating-star.component';
import { MatSelectSearchComponent } from './mat-select-search/mat-select-search.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
    declarations: [
        UserMenuComponent,
        ArraySortPipe,
        UserImgLaodComponent,
        UserImgModalComponent,
        RatingStarComponent,
        MatSelectSearchComponent,
    ],
    imports: [
        CommonModule,
        CoreModule,
        RouterModule,
        ImageCropperModule,
        MatDialogModule,
        MatButtonModule,
        MatCheckboxModule,
        MatRippleModule,
        MatIconModule,
        MatTooltipModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    exports: [
        UserMenuComponent,
        ArraySortPipe,
        UserImgLaodComponent,
        UserImgModalComponent,
        RatingStarComponent,
        MatSelectSearchComponent,
    ],
    providers: [
        StatisticsService,
        ColorService,
        // UserImgServices
    ]
})
export class ShareModule { }
