import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface MatDialogData {
  title: string,
  description: string
}

@Component({
  selector: 'alert-mat-dialog.component',
  templateUrl: 'alert-mat-dialog.component.html',
  styleUrls: ['alert-mat-dialog.component.css']
})
export class AlertMatDialogComponent {
  title: string = "";
  description: string = "";

  constructor(
    public dialogRef: MatDialogRef<AlertMatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialogData
  ) { 
    this.description = data.description;
    this.title = data.title;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}