import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GetFirstCustomError } from '@app/share/custom-validators';
import { Observable, Subscription } from 'rxjs';
import { isNumeric, isObject } from 'rxjs/internal-compatibility';
import { pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-mat-select-search',
  templateUrl: './mat-select-search.component.html',
  styleUrls: ['./mat-select-search.component.scss'],
})
export class MatSelectSearchComponent implements OnInit, OnDestroy {
  @Input()
  val: string | Array<string> = '';
  @Input()
  propToCompare: string;
  @Input()
  name: string;
  @Input()
  required: boolean = false;
  @Input()
  propValue: string;
  @Input()
  label: string;
  @Input()
  appearance: 'legacy' | 'standard' | 'fill' | 'outline' = 'legacy';
  @Input()
  disabled: boolean = false;
  @Output()
  valChange = new EventEmitter<string>();
  @Input()
  formControl: FormControl = null;
  @Input()
  multiple: boolean = false;
  @Input()
  check_all: boolean = false;
  @Input()
  loading: boolean = false;

  check_all_value = false;
  formControlName: string = "";

  filteredData = [];

  @Input()
  data = [];

  search: string = "";

  constructor() { }

  ngOnInit(): void {
    // console.log(this.formControl)
    // console.log(this.label)
    if (this.formControl != null) {
      const controls = this.formControl.parent.controls;
      this.formControlName = Object.keys(controls).find(key => controls[key] == this.formControl)

      // console.log(this.formControl.value);
      // console.log(this.data);
      this.formControl
        .valueChanges
        .subscribe((value) => {
          this.valChanged(value);
        })
      // console.log()
      this.formControl.updateValueAndValidity()
    }

  }

  ngOnDestroy(): void {
  }
  
  valChanged(value : any) {
    if (this.formControl != null) {
      this.check_all_value = this.isEveryChecked;
    } else {
      this.valChange.emit(value);
    }
  }

  get getData() {
    if (this.filteredData.length == 0 && this.search.trim() == "") {
      return this.data;
    }
    return this.filteredData;
  }

  onDataChange(value: string | HTMLElement) {
    if (value instanceof HTMLInputElement) {
      setTimeout(() => {
        this.filteredData = this.data;
        value.value = '';
      }, 350);
      return;
    }
    if (typeof value === 'string') {
      if (value === '') {
        this.filteredData = this.data;
      } else {
        this.filteredData = [];
        const compareFunc = (valueToCompare) => valueToCompare.toLowerCase().includes(value.toLowerCase());
        this.data.forEach(el => {
          if (compareFunc(el[this.propToCompare])) {
            if (typeof el['parent_id'] != 'undefined' && el.parent_id > 0) {
              this.filteredData.push(...this.data.filter(sub_el => sub_el.id == el.parent_id), el);
            } else {
              this.filteredData.push(el);
            }
          }
        });
        this.filteredData = this.filteredData.filter((value, index, self) => self.indexOf(value) === index);
      }
    }
  }

  get getFirstFormError(): string {
    if (this.formControl != null) {
      return GetFirstCustomError(this.formControl.parent as FormGroup, this.formControlName);
    }
    return "";
  }

  isParentChecked(item: any) {
    if (this.multiple == true && parseInt(item["parent_id"]) > 0) {
      if (this.formControl != null) {
        return this.formControl.value?.includes(item["parent_id"]);
      } else if (this.val != null) {
        return (this.val as Array<string>).includes(item["parent_id"]);
      }
    }
    return false
  }

  optionChange(event) {
    if (event.isUserInput && this.multiple) {
      const option = event.source;
      const group = this.data.find(el => el["id"] == option.value);
      if (group["parent_id"] == 0) {
        const siblings = this.data.filter(el => el["parent_id"] == group["id"]).map<String>(el => el["id"]);
        let newValue : Array<String> = [];
        newValue = [...this.formControl.value, ...siblings];
        if (option.selected) {
          this.formControl.setValue(newValue.filter((el, i) => newValue.indexOf(el) == i));
        } else {
          newValue = (this.formControl.value as Array<String>).filter(id => !siblings.includes(id));
          newValue.splice(newValue.indexOf(option.value), 1);
          this.formControl.setValue(newValue);
        }
      }
    }
  }

  checkAll() {
    this.check_all_value = !this.check_all_value;
    if (this.check_all_value) {
      this.formControl.setValue(this.data.map(el => el.id));
    } else {
      this.formControl.setValue([]);
    }
  }

  get isEveryChecked(): boolean {
    const selected = this.formControl.value?.filter(this.onlyUnique);
    const data = this.data.map(el => el['id']);
    return data.length == selected.length && data.every((el) => selected.includes(el));
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
}
