import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as moment from "moment";
import 'moment-timezone';
import { isArray, isObject } from 'rxjs/internal-compatibility';

const MOBILE_PATTERN = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
// const MOBILE_PATTERN = /[0-9\+\-\ ]/;

/**
 * All validators should be used like this
 * 
 * phone: new FormControl('', [Validators.required, CustomValidators.PhoneNumber]),
 */
export class CustomValidators {
  public static PositiveNumber = (control: AbstractControl): ValidationErrors | null => control.value > 0 || control.value == null ? null : { positiveNumber: true };
  public static PhoneNumber = (control: AbstractControl): ValidationErrors | null => {
    const reg = new RegExp(MOBILE_PATTERN);
    return reg.test(control.value) != true && control.value != null && control.value != '' ? { phoneNumber: true } : null;
  }
  public static WhiteSpaces = (control: AbstractControl): ValidationErrors | null => {
    let value: string = control.value;
    if (typeof value == 'string') {
      if (value.length != 0 && value.trim() == '') {
        return { whiteSpaces: true };
      } else {
        return null;
      }
    } else if (value === null) {
      return null;
    } else {
      return { whiteSpaces: true };
    }
  };
  public static Number = (control: AbstractControl): ValidationErrors | null => {
    return isNaN(Number(control.value)) ? { number: true } : null;
  };
  public static RequiredCondition = (condition: boolean): ValidatorFn => {
    return CustomValidators.conditionValidator(condition, Validators.required);
  }
  public static PositiveNumberCondition = (condition: boolean): ValidatorFn => {
    return CustomValidators.conditionValidator(condition, CustomValidators.PositiveNumber);
  }

  private static conditionValidator(condition: boolean, fn: ValidatorFn): ValidatorFn {
    if (condition) {
      return fn;
    }
    return (control: AbstractControl): ValidationErrors | null => null;
  }

  public static In = (arrayOrObject: [] | {}): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as number | string;
      if (
        control.value == null ||
        (isArray(arrayOrObject) && arrayOrObject.find(el => value == el) != undefined) ||
        !isArray(arrayOrObject) && isObject(arrayOrObject) && (
          arrayOrObject[value] != undefined ||
          Object.keys(arrayOrObject).map(key => arrayOrObject[key]).find(el => value == el) != undefined
        )
      ) {
        return null;
      }
      return { notFound: true };
    }

  // Takes control value
  // Takes getTime() of it, takes getTime() of it 
  // Compares date and control.value.getTime()
  public static TimeLaterThan = (datetime: number, timezone: string = null): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const controlValue = control.value;
      let value: number;
      if (control.value instanceof Date) {
        value = controlValue.getTime();
      } else if (isObject(control.value) && control.value['_d'] instanceof Date) {
        value = control.value['_d'].getTime();
      } else if (/[^1-12]?.(AM|PM)/.test(controlValue)) {
        const valueMoment = moment.tz(moment(controlValue, 'HH a').format("YYYY-MM-DD HH:MM"), timezone);
        if (timezone != null) {
          const border_time = moment.unix(datetime).tz(timezone);
          return controlValue != null && valueMoment.isBefore(border_time) ? { timeLateThan: { border_time : border_time.format("h A") } } : null;
        }
      }
      return controlValue != null && value > datetime ? { timeLateThan: true } : null;
    }

  // Takes control value
  // Takes getTime() of it, takes getTime() of it 
  // Compares date and control.value.getTime()
  public static TimeEarlierThan = (datetime: number, timezone: string = null): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const controlValue = control.value;
      let value: number;
      if (control.value instanceof Date) {
        value = controlValue.getTime();
      } else if (isObject(control.value) && control.value['_d'] instanceof Date) {
        value = control.value['_d'].getTime();
      } else if (/[^1-12]?.(AM|PM)/.test(controlValue)) {
        const border_time = moment.unix(datetime).tz("utc");
        const valueMoment = moment.tz(moment(controlValue, 'HH a').format("YYYY-MM-DD HH:MM"), timezone);
        valueMoment.set({
          days:    border_time.get("days"),
        })
        valueMoment.tz("utc");
        if (timezone != null) {
          return controlValue != null && valueMoment.isAfter(border_time) ? { timeEarlierThan: { border_time : border_time.tz(timezone).format("h A") } } : null;
        }
      }
      return controlValue != null && value > datetime ? { timeLateThan: true } : null;
    }

  // Takes control value and compares it with value of passed control by ">"
  public static MoreThanByControl = (controlToCompare: AbstractControl): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      return control.value != null && control.value > controlToCompare.value ? { pattern: true } : null;
    }

  // Takes control value and compares it with value of passed control by "<"
  public static LessThanByControl = (controlToCompare: AbstractControl): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      return control.value != null && control.value < controlToCompare.value ? { pattern: true } : null;
    }
}

export const GetFirstCustomError = (formGroup: FormGroup, controlName: string): string => {
  let control = formGroup.get(controlName);
  for (const error in control.errors) {
    switch (error) {
      case 'positiveNumber':
        return "Must be positive numerical.";
      case 'phoneNumber':
        return "Invalid phone number.";
      case 'whiteSpaces':
        return "This field is required.";
      case 'number':
        return "Must contain only digits.";
      case 'required':
        return "This field is required.";
      case 'email':
        return "Invalid email field.";
      case 'pattern':
        return "Invalid formatted field.";
      case 'timeLateThan':
        if (control.errors[error]['border_time']) {
          return "Must be late than " + control.errors[error]['border_time'];
        }
        return "Invalid date."
      case 'timeEarlierThan':
        if (control.errors[error]['border_time']) {
          return "Must be earlier than " + control.errors[error]['border_time'];
        }
        return "Invalid date."
      case 'notFound':
        return "Invalid value provided.";
    }
  }
  return null;
}