<h1 mat-dialog-title style="display: flex; flex-direction: column; align-items: center;">
  <img style="max-width: 400px; max-height: 200px" src="/assets/pic/header-logo.svg" alt="">
  <p style="text-align: center; margin-top: 30px">
    {{title}}
  </p>
</h1>
<div mat-dialog-content style="text-align: center;">
  <article>
    <ng-container [ngSwitch]="status">
      <ng-container *ngSwitchCase="'loading'">
        <div style="width: 100%; display: flex; justify-content: center;">
          <mat-spinner diameter="24"></mat-spinner>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'confirmed'">
        Congratulatioins, your email will be now <b>{{newEmail}}</b>
      </ng-container>
      <ng-container *ngSwitchDefault>
        Link is out of date or something went wrong.
      </ng-container>
    </ng-container>
  </article>
</div>