import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { isArray } from 'rxjs/internal-compatibility';
import { AuthService } from './auth.service';

@Injectable()
export class AuthPermissionGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    paramKey: string = 'oneOfRequiredPermissions';
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;
        this.authService.redirectUrl = url;
        if (route.data[this.paramKey] && this.authService.user['permissions']) {
            // go through every required param
            for (const permission of route.data[this.paramKey]) {
                // if it's an array, check if every element
                if (isArray(permission)) {
                    let permissionFlag = true;
                    for (const sup_permission of permission) {
                        if (!this.authService.permissions[sup_permission]) {
                            permissionFlag = false;
                            break;
                        }
                    }
                    if (permissionFlag) {
                        return permissionFlag;
                    }
                } else if (this.authService.permissions[permission]) {
                    // otherwise just check permission
                    return true;
                }
            }
        }
        this.router.navigate(['/admin/login']);
        return false;
    }
}
