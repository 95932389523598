import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface MatDialogData {
  title: string,
  description: string,
  button_yes: string,
  button_no: string,
}

@Component({
  selector: 'confirm-mat-dialog.component',
  templateUrl: 'confirm-mat-dialog.component.html',
})
export class ConfirmMatDialogComponent {
  title: string = "";
  description: string = "";
  button_yes: string = "Yes";
  button_no: string = "No";

  constructor(
    public dialogRef: MatDialogRef<ConfirmMatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialogData
  ) { 
    this.description = data.description;
    this.title = data.title;

    if (data.button_yes) {
      this.button_yes = data.button_yes;
    }
    if (data.button_no) {
      this.button_no = data.button_no;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}