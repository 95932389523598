import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { environment } from '../../environments/environment';
import { ApiAdminService } from './api-admin.service';

@Injectable()
export class TimeZonesService {
    private static timezones : object = null;
    constructor(
        private api: ApiAdminService
    ) {}

    public async getTimeZones() {
        if (TimeZonesService.timezones == null) {
            const res = await this.api.send('timezones/all');
            if (res['data']) {
                TimeZonesService.timezones = res['data'] as object;
            }
        }
        return await TimeZonesService.timezones;
    }
}
